import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'
import moment from 'moment'

Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)


export default {
    name: "TresoreriesMouvement",
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function() {

        return {
            pageTitle: "Flux de Trésorerie",

            vuetable: {
                moreParams: {
                    filter: "",
                    criteriacol: "",
                    users_id: "",
                    tresoreries_id: "",
                    type_mvt: "",
                    startDate: moment().format('DD/MM/YYYY'),
                    endDate: moment().format('DD/MM/YYYY'),
                },
                fields: [
                    // {
                    //     name: VuetableFieldCheckbox,
                    //     titleClass: 'text-center',
                    //     dataClass: 'text-center'
                    // },
                    {
                        name: 'id',
                        title: 'ID',
                        sortField: 'id',
                        dataClass: 'text-center',
                        titleClass: 'text-center',
                        width: "80px"
                    },
                    {
                        name: 'date_mvt_formatted',
                        title: 'Date',
                        sortField: 'date_mvt',
                        dataClass: 'text-center',
                        titleClass: 'text-center',
                        width: "80px"
                    },
                    {
                        name: 'num_mvt',
                        title: 'N° Mouvement',
                        sortField: 'num_mvt',
                        dataClass: 'text-center p-0',
                        titleClass: 'text-center',
                        width: "130px"
                    },
                    {
                        name: 'reference_mvt',
                        title: 'Référence',
                        sortField: 'reference_mvt',
                        dataClass: 'text-left',
                    },
                    {
                        name: 'libelle_mvt',
                        title: 'Libéllé',
                        sortField: 'libelle_mvt',
                        dataClass: 'text-left',
                    },
                    {
                        name: 'description',
                        title: 'Description',
                        sortField: 'description',
                        dataClass: 'text-left',
                        visible: false
                    },
                    {
                        name: 'solde_avant',
                        title: 'Solde avant',
                        sortField: 'solde_avant',
                        dataClass: 'text-right',
                        titleClass: 'text-center',
                        width: "150px",
                    },
                    {
                        name: 'montant',
                        title: 'Montant',
                        sortField: 'montant',
                        dataClass: 'text-right',
                        titleClass: 'text-center',
                        width: "150px",
                    },
                    {
                        name: 'solde_apres',
                        title: 'Solde apres',
                        sortField: 'solde_apres',
                        dataClass: 'text-right',
                        titleClass: 'text-center',
                        width: "150px",
                    },
                    {
                        name: 'type_mvt',
                        title: 'Type mvt',
                        sortField: 'type_mvt',
                        dataClass: 'text-center',
                        titleClass: 'text-center',
                        width: "120px",
                    },
                    {
                        name: 'nom',
                        title: 'Utilisateur',
                        sortField: 'nom',
                        dataClass: 'text-left',
                        titleClass: 'text-left',
                        visible: false,
                    },
                    {
                        name: 'date_heure_enregistrement',
                        title: 'Date Enr.',
                        sortField: 'date_heure_enregistrement',
                        dataClass: 'text-center',
                        titleClass: 'text-center',
                        width: "150px",
                        visible: true,
                    },

                    // {
                    //     name: 'actions',
                    //     width: "80px",
                    //     dataClass: "text-center"
                    // }

                ],
                sortOrder: [
                    { field: 'id', direction: 'desc' }
                ],
                css: {
                    table: {
                        // tableClass: 'table-responsive w-100 text-nowrap d-block d-md-table table table-striped table-bordered table-hover',
                        tableClass: 'table-responsive table-striped w-100 d-block d-md-table table table-bordered table-hover',
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars fa-sm',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-secondary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },
            exportexcel: {
                json_fields: {
                    'ID': 'id',
                    'Date': 'date_mvt',
                    'N° Mouvement': 'num_mvt',
                    'Référence': 'reference_mvt',
                    'Libelle': 'libelle_mvt',
                    'Déscription': 'description',
                    'Solde avant': 'solde_avant',
                    'Montant': 'montant',
                    'Solde apres': 'solde_apres',
                    'Type de mouvement': 'type_mvt',
                    'Date Enr.': 'date_heure_enregistrement'
                },
                json_data: [],
                json_meta: [
                    [{
                        'key': 'charset',
                        'value': 'utf-8'
                    }]
                ],
            },
            motCle: "",
            crudform: {
                id: "",
                date_mvt_formatted: moment().format('DD/MM/YYYY'),
                num_mvt: "",
                type_mvt: "",
                reference_mvt: "",
                libelle_mvt: "",
                description: "",
                montant: "",
                tresoreries: "",
                tresoreries_dest_id: "",
                editable: 1
            },
            crudmodaltitle: "Nouveau mouvement de Tresorerie",
            listdata: {
                users: [],
                tresoreries: [],
                libelles: []
            },

            datepicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },
            filtreDate: {
                dateDebut: moment().format('DD/MM/YYYY'),
                dateFin: moment().format('DD/MM/YYYY')
            },


        }
    },
    methods: {
        onRowClass(dataItem, index) {
            if (index == "") console.log("");
            
            // if(Number(dataItem.solde_apres) < 0) {
            //     return 'text-red';
            // }
            return '';
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        editRow(rowData) {

            //alert("You clicked edit on" + JSON.stringify(rowData))
            axios.get(this.BASE_URL + "/tresoreriesmouvement/get/" + rowData.id).then(response => {
                this.crudform = response.data[0];
                if (this.crudform.type_mvt == "TRANSFERT ENVOYE") {
                    this.crudform.type_mvt = "TRANSFERT";

                }
                if (this.crudform.type_mvt == "TRANSFERT RECU") {
                    this.crudform.type_mvt = "TRANSFERT";
                    this.crudform.editable = 0;
                    // this.crudform.tresoreries_id = this.crudform.source_id;
                    // this.crudform.tresoreries_dest_id = this.crudform.tresoreries_id;
                }


            });
            this.crudmodaltitle = "Editer un mouvement de trésorérie";
            this.openModal();
        },
        deleteRow(rowData) {
            //alert("You clicked delete on" + JSON.stringify(rowData));
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Supprimer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        axios.post(that.BASE_URL + "/tresoreriesmouvement/delete/" + rowData.id).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log(response.data.message);
                                that.setFilter();
                                Vue.$toast.open({
                                    message: response.data.message,
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                })

        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
            this.$refs.vuetable.$data.tableData
        },
        setFilter() {

            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.criteriacol = this.criteriacol;
            this.vuetable.moreParams.startDate = this.filtreDate.dateDebut;
            this.vuetable.moreParams.endDate = this.filtreDate.dateFin;


            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.vuetable.moreParams.users_id = "";
            this.vuetable.moreParams.type_mvt = "";
            this.vuetable.moreParams.tresoreries_id = "";
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        onExport() {
            this.exportexcel.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        openModal() {
            this.$bvModal.show("crudmodal");
        },
        closeModal() {
            this.$bvModal.hide("crudmodal");
        },
        openFilterModal() {
            this.$bvModal.show("filterModal");
        },
        closeFilterModal() {
            this.$bvModal.hide("filterModal");
        },
        addRow() {
            this.crudmodaltitle = "Nouveau mouvement de Trésorésie";
            this.crudform = {
                id: "",
                date_mvt_formatted: moment().format('DD/MM/YYYY'),
                num_mvt: "",
                type_mvt: "",
                reference_mvt: "",
                libelle_mvt: "",
                description: "",
                montant: "",
                tresoreries: "",
                tresoreries_dest_id: "",
                editable: 1
            };
            this.openModal();
        },
        onSubmit() {
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'Continuer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = that.crudform.id == '' ? that.BASE_URL + "/tresoreriesmouvement/addaction" : that.BASE_URL + "/tresoreriesmouvement/editaction";
                        axios.post(link, $("#formulaire").serialize()).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                that.closeModal();
                                that.setFilter();
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        fetchData: function() {
            axios.get(this.BASE_URL + "/tresoreriesmouvement/fetchdata").then(response => {
                this.listdata = response.data;
            });

        },

        delFilter(model) {
            this.vuetable.moreParams[model] = "";
            this.setFilter();
        },
        initDate() {
            var currentDateWithFormat = moment().format('DD/MM/YYYY');
            if (localStorage.dateFlux) {
                var dateFlux = JSON.parse(localStorage.dateFlux);
                // Si les deux dates etait égaux on met la date d'aujourdui
                if (dateFlux.dateDebut == dateFlux.dateFin) {
                    dateFlux = {
                        dateDebut: currentDateWithFormat,
                        dateFin: currentDateWithFormat
                    };
                    localStorage.dateFlux = JSON.stringify(dateFlux);
                }
                this.filtreDate = {
                    dateDebut: dateFlux.dateDebut,
                    dateFin: dateFlux.dateFin
                };
            } else {
                dateFlux = {
                    dateDebut: currentDateWithFormat,
                    dateFin: currentDateWithFormat
                };
                localStorage.dateFlux = JSON.stringify(dateFlux);
                this.filtreDate = {
                    dateDebut: dateFlux.dateDebut,
                    dateFin: dateFlux.dateFin
                };
            }
        },
        setDate() {
            localStorage.dateFlux = JSON.stringify(this.filtreDate);
        },
        getLibelle() {
            axios.get(this.BASE_URL + "/tresoreriesmouvement/getlibelle/" + this.crudform.type_mvt).then(response => {
                this.listdata.libelles = response.data.map(o => o.libelle_mvt);
                console.log(this.listdata.libelles);
            });
        }

    },
    computed: {
        filterId() {
            return (list, libelle, id) => {
                if (!list || list.length == 0 || !(id > 0))
                    return "";
                return list.filter(item => item.id == id)[0][libelle];
            };
        },
        tresoreriesdest() {
            return this.listdata.tresoreries.filter(t => t.id != this.crudform.tresoreries_id);
        },
        total_data() {
            var data = this.listdata.tresoreries.filter(o => this.vuetable.moreParams.tresoreries_id == '' || o.id == this.vuetable.moreParams.tresoreries_id);
            data.forEach(o => {
                o.total_credit = 0;
                o.total_debit = 0;
            });
            if (this.$refs.vuetable && this.$refs.vuetable.$data.tablePagination) {
                var total = this.$refs.vuetable.$data.tablePagination.total;
                data.forEach(o => {
                    o.total_credit = total.total_credit.filter(r => r.tresoreries_id == o.id).length > 0 ? total.total_credit.filter(r => r.tresoreries_id == o.id)[0].total_credit : 0;
                    o.total_debit = total.total_debit.filter(r => r.tresoreries_id == o.id).length > 0 ? total.total_debit.filter(r => r.tresoreries_id == o.id)[0].total_debit : 0;
                });
            }
            console.log(data);
            return data;

        }

    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        console.log(this.$route);
        if (this.$route.name == 'TresoreriesFluxparid' && this.$route.params.tresoreries_id) {
            this.vuetable.moreParams.tresoreries_id = this.$route.params.tresoreries_id;
        }

        this.initDate();

        this.motCle = "";
        this.criteriacol = "";
        this.fetchData();
        this.setFilter();
    },
    mounted: function() {
        if (this.$route.params.type == 'add') {
            this.addRow();
        }
        console.log(this.$refs.vuetable.$data)
    },
    watch: {
        'crudform.type_mvt': function() {
            this.getLibelle();
        }
    }
}